import React from "react";
import PropTypes from "prop-types";
import { Link, graphql } from "gatsby";
import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";

import Layout from "../components/Layout";

export const OperationsPageTemplate = ({
  image,
  title,
  heading,
  subheading,
  mainpitch,
  description,
  intro,
}) => (
  <div>
    <section className="home-hero">
      <InView threshold={0.2}>
        {({ ref, inView }) => (
          <motion.div
            ref={ref}
            initial={{ opacity: 0, transform: "translate(-50px)" }}
            animate={
              inView
                ? { opacity: 1, transform: "translate(0px)" }
                : { opacity: 1 }
            }
            transition={{
              duration: 0.5,
              delay: 0.25,
            }}
            className="ontopyo"
          >
            <h1
              data-scroll
              data-scroll-direction="horizontal"
              data-scroll-speed="2"
              data-scroll-position="top"
              data-scroll-delay="0.2"
            >
              SPECIAL
              <br />
              OPERATIONS
            </h1>
          </motion.div>
        )}
      </InView>

      <div className="scroll-line">
        <div className="center" />

        <div data-scroll data-scroll-speed="-1" className="marker" />
      </div>

      <div className="video-container">
        <div className="video-overlay" />
        <img src="../img/hero-operations.jpg" />
      </div>
    </section>
    <div className="about-heli">
      <div className="intro-heli">
        <InView threshold={0.2}>
          {({ ref, inView }) => (
            <motion.div
              ref={ref}
              initial={{ opacity: 0 }}
              animate={inView ? { opacity: 1 } : { opacity: 1 }}
              transition={{
                duration: 0.25,
                delay: 0.1,
              }}
            >
              <p data-scroll data-scroll-speed="-1" data-scroll-delay="0.2">
                With the ability to hover at all heights with either the window
                or door removed, helicopters provide the photographer with a
                platform for detailed aerial photographs. Low-level aerial
                photography can offer architectural, abstract or landscape
                images to the professional.
                <br />
                <br />
                The Twin Squirrel is an ideal platform for helicopter aerial
                photography. The sliding doors mean you can travel at cruise
                speed (115kts) to your location, slow down, open the door and
                get the shot you need. The twin-engine ability also allows us
                off-shore, over other built-up areas and at heights that
                single-engine helicopters cannot achieve.
              </p>
            </motion.div>
          )}
        </InView>
        <InView threshold={0.2}>
          {({ ref, inView }) => (
            <motion.h1
              ref={ref}
              initial={{ opacity: 0 }}
              animate={inView ? { opacity: 1 } : { opacity: 1 }}
              transition={{
                duration: 0.25,
                delay: 0.1,
              }}
            >
              <span data-scroll data-scroll-speed="1.5" data-scroll-delay="0.2">
                Filming,
              </span>
              <span data-scroll data-scroll-speed="1" data-scroll-delay="0.4">
                Surveys
              </span>
              <span data-scroll data-scroll-speed="0.5" data-scroll-delay="0.6">
                & More
              </span>
            </motion.h1>
          )}
        </InView>
      </div>
    </div>
    <InView threshold={0.2}>
      {({ ref, inView }) => (
        <motion.div
          ref={ref}
          initial={{ opacity: 0 }}
          animate={inView ? { opacity: 1 } : { opacity: 1 }}
          transition={{
            duration: 0.25,
          }}
          className="images-heli"
        >
          <div data-scroll data-scroll-speed="3" className="img50">
            <p>
              Helicopters provide a fantastic platform for aerial photography.
            </p>
          </div>
          <div data-scroll data-scroll-speed="1" className="img50">
            <img src="../img/operations.jpg" />
          </div>
        </motion.div>
      )}
    </InView>
    <div className="further-heli">
      <div className="twoup">
        <InView threshold={0.2}>
          {({ ref, inView }) => (
            <motion.div
              ref={ref}
              initial={{ opacity: 0 }}
              animate={inView ? { opacity: 1 } : { opacity: 1 }}
              transition={{
                duration: 0.25,
                delay: 0.1,
              }}
              className="heli-block"
            >
              <h2>Business Charter</h2>
              <span className="heli-line" />
              <p>
                Based at Stapleford Aerodrome, just inside the M25 at the M11
                junction, we are in a unique position to offer photographers
                access for aerial photographs of London. With just a 5-minute
                sector time to the Queen Elizabeth Olympic Park, and only a few
                minutes more to the City of London, we regularly fly for
                developers, marketing companies and architects alike.
                <br />
                <br />
                As we fly so regularly in the London area we have all the
                necessary permits required to operate down to 750 feet;
                including the restricted zones around the City, West End and
                Canary Wharf.
                <br />
                <br />
                We have worked on a variety of commissions, from small unique
                shots (advertising companies, estate agents) to long-term
                contracts (Westfield Shopping Centres, London 2012 Olympics).
              </p>
            </motion.div>
          )}
        </InView>

        <InView threshold={0.2}>
          {({ ref, inView }) => (
            <motion.div
              ref={ref}
              initial={{ opacity: 0 }}
              animate={inView ? { opacity: 1 } : { opacity: 1 }}
              transition={{
                duration: 0.25,
                delay: 0.1,
              }}
              className="heli-block"
            >
              <p>
                Getting the best image is a matter of teamwork between the
                photographer and pilot; we have over 10 years experience with
                some of the most demanding aerial photographers. For helicopter
                photography in Essex, the South East and even nationwide, please
                call the Operations team to discuss rates.
                <br />
                <br />
                During the Summer months we work with Heli-Wise providing
                helicopters for their school aerial photographs. Excel Helicopters
                offers the option of departing either from Stapleford Aerodrome
                or a private site of your choice (subject to the site being
                approved for helicopter operations). A London sightseeing tour
                can also be included at an additional charge. Overnight returns
                can also be arranged
              </p>
              <span className="heli-line" />
              <div className="bullets-container">
                <div className="bullets">
                  <img src="../img/bullet.svg" />
                  <p>CINEFLEX HD SYSTEM</p>
                </div>
                <div className="bullets">
                  <img src="../img/bullet.svg" />
                  <p>ULTRAMEDIA II</p>
                </div>
                <div className="bullets">
                  <img src="../img/bullet.svg" />
                  <p>GYRON FS / STAB-C</p>
                </div>
                <div className="bullets">
                  <img src="../img/bullet.svg" />
                  <p>TYLER MIDDLE MOUNT</p>
                </div>
              </div>
            </motion.div>
          )}
        </InView>
      </div>
    </div>
    <div className="heli-grey">
      <div className="twoup">
        <div className="item">
          <div className="item-image-holder">
            <img
              data-scroll
              data-scroll-speed=".5"
              data-scroll-delay="0.2"
              className="ig25"
              src="../img/op-img1.jpg"
            />
            <img
              data-scroll
              data-scroll-speed="2.5"
              data-scroll-delay="0.2"
              className="ig25"
              src="../img/op-img2.jpg"
            />
          </div>
          <p>
            Our helicopters routinely spend time out of the country on aerial
            filming in Ireland and mainland Europe. Excel Helicopters have a
            great depth of experience in all types of aerial filming, including
            live broadcast, news gathering, sporting events, film productions,
            television series and commercials.
          </p>
        </div>
        <div className="item special">
          <p data-scroll data-scroll-speed="3.5" data-scroll-delay="0.2">
            The large payload of our helicopters enables them to remain airborne
            for two to three hours, depending on camera fitted, allowing
            off-shore shoots, long sequences and plenty of re-takes.
          </p>
        </div>
      </div>
    </div>
    <div className="block-heli">
      <div className="threeup">
        <InView threshold={0.2}>
          {({ ref, inView }) => (
            <motion.div
              ref={ref}
              initial={{ opacity: 0 }}
              animate={inView ? { opacity: 1 } : { opacity: 1 }}
              transition={{
                duration: 0.25,
                delay: 0.1,
              }}
              className="heli-block"
            >
              <h2>
                AERIAl
                <br />
                SURVEYS
              </h2>
              <span className="heli-line" />
              <p>
                We have experience in rail, road, pipeline and grid surveying.
                Recently working with The London Underground, the Environment
                Agency, Traffic Master, Corporation of London and the National
                Grid.
                <br />
                <br />
                LiDAR laser altimetry and thermal imaging (TI) are two areas of
                growth in aerial surveying. Helicopters give the opportunity to
                fly at heights of 750ft over congested/built up areas at a speed
                to pick up the most detailed information.
                <br />
                <br />
                Lightweight Twin Squirrels have the performance and stability
                needed to perform these tasks. Combined with our experienced
                pilots we can confidently tender for most requirements.
              </p>
            </motion.div>
          )}
        </InView>

        <InView threshold={0.2}>
          {({ ref, inView }) => (
            <motion.div
              ref={ref}
              initial={{ opacity: 0 }}
              animate={inView ? { opacity: 1 } : { opacity: 1 }}
              transition={{
                duration: 0.25,
                delay: 0.1,
              }}
              className="heli-block"
            >
              <h2>
                FREIGHT
                <br />
                TRANSPORT
              </h2>
              <span className="heli-line" />
              <p>
                Our fleet of Twin Squirrels and 109s are kept in a ready-to-fly
                condition at our base inside the M25, at the M11 junction. With
                maintenance support, our own fuel and full-time pilots at our
                base, we can respond immediately to any request.
                <br />
                <br />
                The cabin of the Twin Squirrel can take large loads with the
                rear seats removed, with a payload of up to 800 kg (depending on
                fuel load and centre of gravity considerations). Twin-engine
                performance gives us the ability to land in congested areas,
                such as docks or business premises (size permitting). Extra
                speed, flying up to 140 mph and the ability to fly at night. We
                offer a secure, fast and convenient alternative to road or rail
                for time-critical freight.
              </p>
            </motion.div>
          )}
        </InView>
        <InView threshold={0.2}>
          {({ ref, inView }) => (
            <motion.div
              ref={ref}
              initial={{ opacity: 0 }}
              animate={inView ? { opacity: 1 } : { opacity: 1 }}
              transition={{
                duration: 0.25,
                delay: 0.1,
              }}
              className="heli-block"
            >
              <h2>
                MEDEVAC
                <br />& H.E.M.S
              </h2>
              <span className="heli-line" />
              <p>
                Four of our fleet of Twin Squirrel helicopters can be
                re-configured to take a variety of stretcher fits, for one or
                two patients.
                <br />
                <br />
                The AS355 Twin Squirrel offers several advantages in this role:
                <br />
                <br />
                Twin-engine performance, enabling work in congested/crowded
                areas. Both at the incident site and the receiving hospital.
                140mph top speed to transfer patients quickly.
                <br />
                <br />
                Ease of access for stretcher with sliding doors and high-skids.
                <br />
                <br />
                Light-weight helicopter allows for more payload; paramedic,
                doctor and patient.
              </p>
            </motion.div>
          )}
        </InView>
      </div>
    </div>
  </div>
);

OperationsPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  mainpitch: PropTypes.object,
  description: PropTypes.string,
  intro: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
};

const OperationsPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <OperationsPageTemplate
        image={frontmatter.image}
        title={frontmatter.title}
        heading={frontmatter.heading}
        subheading={frontmatter.subheading}
        mainpitch={frontmatter.mainpitch}
        description={frontmatter.description}
        intro={frontmatter.intro}
      />
    </Layout>
  );
};

OperationsPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default OperationsPage;

export const pageQuery = graphql`
  query OperationsPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        heading
        subheading
        mainpitch {
          title
          description
        }
        description
        intro {
          blurbs {
            text
          }
          heading
          description
        }
      }
    }
  }
`;
